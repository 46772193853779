import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Typography,
} from "@mui/material";

import { db } from "../../../firebase-config.";
import { v4 as uuidv4 } from "uuid";

import { GeoFirestore } from "geofirestore"; // Ensure correct import path
import firebase from "firebase/compat/app";
import { generateCleanWordsWithLimit } from "../../../utils/utility";
import LoadingButton from "../../Elements/LoadingButton/LoadingButton";
import dayjs from "dayjs";

// import UpdateImageUrls from "../../Elements/UpdateImageUrls";
const geoFirestore = new GeoFirestore(db);

const UploadEvents = ({ open, handleClose, venue }) => {
  const [jsonContent, setJsonContent] = useState("");
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // Function to handle text change in text field
  const handleChange = (e) => {
    setJsonContent(e.target.value);
    setError("");
  };
  const removeCommas = (str) => {
    if (!str) return null;
    if (str.startsWith(",")) {
      str = str.slice(1);
    }
    if (str.endsWith(",")) {
      str = str.slice(0, -1);
    }
    return str;
  };

  // Function to validate and upload JSON content
  const handleUpload = () => {
    try {
      const parsedJson = JSON.parse(removeCommas(jsonContent));

      // Check if it's an array of objects
      if (
        Array.isArray(parsedJson) &&
        parsedJson.every((item) => typeof item === "object")
      ) {
        uploadEventsData(parsedJson)
          .then(() => {
            setMessage("All documents uploaded successfully");
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
        // You can process the 'venue' prop here if necessary
        // handleClose();
      } else {
        setError("Invalid JSON: The content must be an array of objects.");
      }
    } catch (e) {
      setError(`Invalid JSON format. Error: ${e.message}`);
    }
  };
  const placeholderJson = `[
    {
      "fbid": "", 
      "main_date": "2023-01-29 16:30:00.0", 
      "ticket_url": "", 
      "title": "Chelsea Takami", 
      "eventdescription": "", 
      "user": "bulkupload",
      "eventtype": "Music"
    },
    {
      "fbid": "", 
      "main_date": "2023-01-30 16:30:00.0", 
      "ticket_url": "", 
      "title": "Chelsea Takami", 
      "eventdescription": "", 
      "user": "bulkupload",
      "eventtype": "Music"
    }
  ]`;
  const uploadEventsData = async (jsonData) => {
    setIsLoading(true);
    try {
      const geocollection = geoFirestore.collection("events");

      //   const bandsRef = query(collection(db, "bands"));
      //   const bandsSnapshot = await getDocs(bandsRef);
      //   const bandsMap = {};

      //   bandsSnapshot.forEach((doc) => {
      //     bandsMap[doc.id] = doc.data(); // Store the full band object
      //   });

      // Step 1: Get the IDs of existing events
      //   const existingEventsSnapshot = await geocollection.get();
      //   const existingEventIds = new Set(
      //     existingEventsSnapshot.docs.map((doc) => doc.id)
      //   );

      // Step 2: Get all venues at once and create a dictionary for quick lookup
      //   const venuesSnapshot = await venuesRef.get();
      //   const venuesMap = {};
      //   venuesSnapshot.forEach((doc) => {
      //     venuesMap[doc.id] = doc.data();
      //   });

      const BATCH_SIZE = 500; // Maximum batch size
      let batch = geoFirestore.batch();
      let batchCounter = 0;

      for (const item of jsonData) {
        const eventId = item.id ? item.id + "" : uuidv4();
        const mainDate = item?.main_date;
        const modifiedDate = item?.modifieddate;

        const nyDate = new Date(mainDate + " GMT-0400");
        let utcDate;
        try {
          utcDate = new Date(nyDate.toISOString());
        } catch (e) {
          console.log("Error", e, item);
        }

        const timestamp = firebase.firestore.Timestamp.fromDate(utcDate);
        item.main_date = timestamp;
        item.modifieddate = firebase.firestore.Timestamp.fromDate(
          new Date(dayjs().toISOString())
        );
        item.title_lowercase = item?.title.toLowerCase();
        item.searchTerms = generateCleanWordsWithLimit(
          item?.title.toLowerCase()
        );
        item.city = venue?.venue_city;
        item.state = venue?.venue_state;
        item.venue_state = venue?.venue_state;
        item.venue_name = venue?.venue_name;
        item.address = venue?.address1;
        item.zip = venue?.VENUE_ZIP;
        item.coordinates = new firebase.firestore.GeoPoint(
          parseFloat(venue?.lat),
          parseFloat(venue.lon)
        );
        item.bands = [];
        item.venue = venue?.id + "";
        item.vid = venue?.id + "";
        if (item?.date_utc === "") item.date_utc = null;
        if (item?.guests === "") item.guests = null;
        if (item?.highlight === "") item.highlight = null;
        if (item?.tzone === "") item.tzone = null;
        if (item?.title_url === "") item.title_url = null;
        item.id = eventId;
        // let bidArray = [];
        // for (let i = 1; i <= 5; i++) {
        //   const bidKey = `bid${i}`;
        //   if (
        //     item[bidKey] &&
        //     item[bidKey] !== null &&
        //     item[bidKey] !== 0 &&
        //     item[bidKey] !== ""
        //   ) {
        //     bidArray.push(item[bidKey] + "");
        //   }
        //   delete item[bidKey];
        // }

        // item.bandIds = bidArray;
        // const updatedBands = bidArray.map(
        //   (bandId) =>
        //     bandsMap[bandId] || { id: bandId, bandname: "Unknown Band" }
        // );
        // item.bands = updatedBands;

        const docRef = geocollection.doc(eventId);
        batch.set(docRef, item); // Add set operation to batch
        batchCounter++;

        // Commit the batch if it reaches the BATCH_SIZE
        if (batchCounter === BATCH_SIZE) {
          console.warn("Batch Comitted");
          await batch.commit();
          batch = geoFirestore.batch(); // Reset batch
          batchCounter = 0;
        }
      }

      // Commit any remaining documents in the final batch
      if (batchCounter > 0) {
        await batch.commit();
      }

      console.log("All documents uploaded successfully");
    } catch (error) {
      setError("Error Uploading JSON:", error);
      console.error("Error fetching JSON:", error);
    }
  };
  const clearState = () => {
    setIsLoading(false);
    setJsonContent("");
    setError("");
    setMessage("");
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={() => {
        clearState();
      }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Upload Events for Venue: {venue?.venue_name}</DialogTitle>
      <DialogContent>
        <TextField
          //   label="JSON Array of Events"
          placeholder={placeholderJson}
          fullWidth
          multiline
          rows={10}
          variant="outlined"
          value={jsonContent}
          onChange={handleChange}
          error={!!error}
        />
        {error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
        {message && !error && (
          <Typography color="success" variant="body2">
            {message}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={clearState} color="secondary">
          Cancel
        </Button>
        <LoadingButton
          isLoading={isLoading}
          onClick={handleUpload}
          variant="contained"
          color="primary"
        >
          Upload
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default UploadEvents;
